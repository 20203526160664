<template>
  <div>
    <div>
      <Row
        class="m-b-10"
        :gutter="8"
      >
        <i-col span="24">
          <Input
            search
            size="small"
            enter-button="搜索"
            v-model="query.key"
            placeholder="关键词"
            @on-search="handelSearch"
          />
        </i-col>
      </Row>
      <div>
        <Table
          stripe
          size="small"
          :data="tableData.list"
          :columns="talbeColumns"
        ></Table>
        <div class="paging_style">
          <Page size="small"
            :total="tableData.totalRow"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="changePage"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { deleteWorkGroup } from '@/api/os/workgroup'
// import { formatWorkGroupType } from '@/utils/tagStatus'
export default {
  mixins: [sysMixins],
  data () {
    return {
      query: {
        key: '',
        pageNumber: 1,
        pageSize: 15,
        companyId: this.$store.getters.token.userInfo.companyId
      },
      talbeColumns: [
        { title: '编号', align: 'center', key: 'id' },
        { title: '名称', align: 'center', key: 'name' },
        // {
        //   title: '类别',
        //   align: 'center',
        //   render: (h, parmas) => {
        //     return formatWorkGroupType(h, parmas.row.type)
        //   }
        // },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$store.commit('set_work_groupId', params.row.id)
                    this.handelViewDetail()
                  }
                }
              }, '详情'),
              h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handelDelete(params.row.id)
                  }
                }
              }, '删除')
            ])
          }
        }
      ]
    }
  },
  computed: {
    tableData () {
      return this.$store.state.workGroup.workGroupData
    }
  },
  created () {
    this.getTableData()

    // 设置头部路由标签
    this.setLinkTagArray({ key: 'groupIndex', value: '小组列表' })
    this.setLinkRouterMaps(['groupIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('groupIndex')
  },
  methods: {
    getTableData () {
      this.$store.dispatch('getWorkGroupData', this.query)
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    handelSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },

    handelDelete (id) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该数据？',
        onOk: () => {
          deleteWorkGroup({ workGroupId: id }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
            }
          })
        }
      })
    },
    /**
     * 处理页面渲染
     */
    handleIndexPage () {
      this.setShowLeftBlock(true)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('AddGroup')
      this.setBottomComponent('IndexTable')
      this.setRightComponent('')
    },
    handelViewDetail () {
      this.setLeftComponent('AddGroupUser')
      this.setBottomComponent('GroupDetail')
    }
  }
}
</script>
